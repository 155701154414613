import { stopSipnner, startSipnner } from "../../utils/spinner";
import { baseApiInstance, scopeApiInstance } from "../../dataProvider/axiosHelper"
import { ORGANIZATION_DETAILS_URL, ORGANIZATION_UPDATE_DETAILS_URL, ORGANIZATION_USERS_URL_V2, UNBLOCK_USER_URL_V2, ACTIVATE_STAFF_URL_V2, ORG_LINK_ACCOUNTING_SERVICE_URL } from "../../dataProvider/constant"
import { Strings } from "../../dataProvider/localize";

export const getFullOrgDetails = (abortController) => () => {
  return baseApiInstance.get(ORGANIZATION_DETAILS_URL).then((res) => {
    if (res.data && res.data.data && res.data.data.organisation_detail) {
      return Promise.resolve(res.data.data.organisation_detail);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      )
    } else {
      return Promise.reject(null);
    }
  })
}

export const getOrgUsersBySearch = (params = {}) => () => {
  return baseApiInstance.get(ORGANIZATION_USERS_URL_V2, { params: { ...params } }).then((res) => {
    if (res.data && res.data.data && res.data.data.orgUsers) {
      return Promise.resolve(res.data.data.orgUsers);
    } else {
      return Promise.reject(Strings.generic_error);
    }
  }).catch(error => {
    if (error) {
      return Promise.reject(
        error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      )
    } else {
      return Promise.reject(null);
    }
  })
}

export const updateOrgDetails = (formData) => () => {
  return baseApiInstance.put(ORGANIZATION_UPDATE_DETAILS_URL, formData)
    .then(res => {
      let data = res.data;
      if (data) {
        return Promise.resolve(data.message)
      }
      else {
        return Promise.reject()
      }
    })
    .catch(error => {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    });
}

export const updateInviteNewUser = (formData) => () => {
  return baseApiInstance.post(ORGANIZATION_USERS_URL_V2, formData).then((res) => {
    if (res.data && res.data.message) {
      return Promise.resolve(res.data.message);
    } else {
      return Promise.reject()
    }
  }).catch((error) => {
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const updateStaffStatus = (formData) => dispatch => {
  startSipnner(dispatch)
  return baseApiInstance.put(ACTIVATE_STAFF_URL_V2, formData).then(res => {
    stopSipnner(dispatch);
    if (res && res.data && res.data.message) {
      return Promise.resolve(res.data.message)
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    stopSipnner(dispatch);
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  });
}

export const unblockUserMobileAccess = (formData) => dispatch => {
  startSipnner(dispatch);
  return baseApiInstance.put(UNBLOCK_USER_URL_V2, formData).then(res => {
    stopSipnner(dispatch);
    if (res && res.data && res.data.message) {
      return Promise.resolve(res.data.message)
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    stopSipnner(dispatch)
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

export const linkAccountingService = (formData) => dispatch => {
  startSipnner(dispatch);
  return scopeApiInstance.post(ORG_LINK_ACCOUNTING_SERVICE_URL, formData).then(res => {
    stopSipnner(dispatch);
    if (res && res.data) {
      return Promise.resolve(res.data)
    } else {
      return Promise.reject();
    }
  }).catch(error => {
    stopSipnner(dispatch)
    if (error) {
      return Promise.reject(
        error && error.response && error.response.data && error.response.data.message ? error.response.data.message : Strings.network_error
      );
    } else {
      return Promise.reject(null);
    }
  })
}

